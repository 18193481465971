<template>
  <div class="content">
    <van-nav-bar title="停机保号" left-arrow @click-left="onClickLeft" />
    <div class="box">
      <div class="head">
        <img
          src="../../assets/image/cardManagement/img_downtimeguaranteecard.png"
          alt=""
        />
      </div>
      <div
        class="account_desc"
        :class="{ account_desc_: cardStatus.isStopSave }"
      >
        <div class="account_content">
          <div class="tips">卡号:</div>
          <div class="detail">{{ card }}</div>
        </div>
        <div class="account_content">
          <div class="tips">账号/卡余额:</div>
          <div class="detail">{{ userMoney }}/{{ cardMoney }}</div>
        </div>
        <div class="account_content" v-if="cardStatus.isStopSave">
          <div class="tips">当前保号时间:</div>
          <div class="detail">
            {{ cardStatus.stopSaveBeginTime.slice(0, 11) }}到{{
              cardStatus.stopSaveEndTime.slice(0, 11)
            }}
          </div>
        </div>
        <div class="account_content">
          <div class="tips">当前保号状态:</div>
          <div class="detail">
            {{ cardStatus.isStopSave ? "已保号" : "尚未保号" }}
          </div>
        </div>
      </div>
      <ul class="setMeal_choice" v-if="dataList.length > 0">
        <li
          class="choice_box"
          v-for="(item, index) in dataList"
          :key="index"
          @click="paste(item)"
          :class="{ active: packageName == item.packageName }"
        >
          <div class="top_price">{{ item.packageName }}</div>
          <div class="bottom_price">{{ item.price }}¥</div>
        </li>
      </ul>
      <ul class="empty_list" v-else>
        <li>
          暂无停机保号套餐包~~~
        </li>
      </ul>
      <div class="explain">
        <div class="explain_desc">说明:</div>
        <div class="explain_text">
          1、如果您当前有生效的套餐，充值停机保号以后，原有的套餐将会失效，充值前请确认。
        </div>
        <div class="explain_text">
          2、在停机保号期间，充值套餐，停机保号将会立即失效，进入到套餐周期
        </div>
        <div class="explain_text">3、一旦订购停机保号，将无法退款</div>
      </div>
    </div>
    <!-- 充值弹窗 -->
    <popup-container
      ref="actionNotice"
      :title="title"
      :stopPackage="true"
      :chargeNum="Number(chargeNum)"
      :setMealObject="setMealObject"
      :userMoney="Number(userMoney)"
      :cardMoney="Number(cardMoney)"
    ></popup-container>
		<!--确认订单组件-->
    <confirm-order
      v-if="theme === 'YuYin'"
      ref="confirmOrderRef"
      :stopPackage="true"
      :title="title"
      :chargeNum="Number(chargeNum)"
      :setMealObject="setMealObject"
    ></confirm-order>
  </div>
</template>

<script>
// 需要哪些模块按需引入
import { onMounted, reactive, toRefs, getCurrentInstance, nextTick } from "vue";
import { queryStopPackage } from "_API_/api.services";
export default {
  setup() {
    // 个人觉着还是这样写舒服一点 类似于vue2中的data
    const state = reactive({
      card: "", //卡号
      title: "", //弹窗顶部标题
      packageName: "", //选中的套餐名称
      chargeNum: "", // 选中余额充值包的金额
      setMealObject: {}, //套餐对象
      cardStatus: {}, //停机保号状态
      dataList: [], //数据源
      userMoney: "", //账户余额
      cardMoney: "", //卡余额
      theme: "" //主题名称
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    const methods = {
      onClickLeft() {
        router.push({
          name: "personal"
        });
      },
      // 选中套餐充值包的方法
      paste(item) {
        state.title = "套餐详情";
        state.chargeNum = Number(item.price);
        state.setMealObject = item;
        state.packageName = item.packageName;
        nextTick(() => {
					if(state.theme === 'YuYin'){
						state.title = "确认订单"
						ctx.$refs.confirmOrderRef.payInfo.money = Number(item.price) || 0;
						ctx.$refs.confirmOrderRef.isShow();
						ctx.$refs.confirmOrderRef.actionType = 1;
						ctx.$refs.confirmOrderRef.payInfo.orderType = "stopOrder";
						return
					}
          ctx.$refs.actionNotice.payInfo.money = Number(item.price) || 0;
          ctx.$refs.actionNotice.isShow();
          ctx.$refs.actionNotice.actionType = 1;
          ctx.$refs.actionNotice.payInfo.orderType = "stopOrder";
        });
      },
      //获取套餐列表
      getList() {
        queryStopPackage(state.card).then(({ data: res }) => {
          if (res.code == 0) {
            state.cardStatus = res.list;
            state.cardMoney = res.list.money || 0; //卡余额
						state.userMoney = res.list.userMoney || 0; //账户余额
            state.dataList = res.list.basicPackage;
            return;
          }
          tool.toast({
            msg: res.msg,
            duration: 1500
          });
        });
      }
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
      state.card = info.virtualId || info.iccid; //卡号
      ctx.getList();
    });
    return { ...toRefs(state), ...methods };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.content {
  height: 100vh;
  .box {
    height: calc(100vh - 120px);
    overflow-y: auto;
    .head {
      width: 750px;
      height: 518px;
      background: #2196f3;
      text-align: center;
      padding-top: 20px;
      img {
        width: 219px;
        height: 219px;
      }
    }
    .account_desc,
    .account_desc_ {
      width: 690px;
      background: #ffffff;
      border-radius: 20px;
      margin: 0 auto;
      position: relative;
      bottom: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .account_content {
        width: 640px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
      }
    }
    .account_desc {
      height: 200px;
    }
    .account_desc_ {
      height: 260px;
    }

    .setMeal_choice {
      width: 710px;
      display: flex;
      text-align: left;
      margin: 20px auto 0px;
      flex-wrap: wrap;
      .choice_box {
        width: 210px;
        height: 142px;
        background: #ffffff;
        border-radius: 20px;
        border: 2px solid #2196f3;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 10px 10px 0px;
        .top_price {
          font: {
            size: 26px;
            weight: bold;
          }
          color: #333333;
        }
        .bottom_price {
          font: {
            size: 26px;
            weight: 400;
          }
          color: #aaaaaa;
        }
      }
      .active {
        background: rgba(33, 150, 243, 0.1);
        border-radius: 20px;
        border: 2px solid #2196f3;
      }
    }
    .empty_list {
      width: 590px;
      height: auto;
      background: #ffffff;
      border-radius: 20px;
      margin: 20px auto;
      font-size: 26px;
      color: #b2b2b2;
      padding: 40px;
      text-align: center;
    }
    .explain {
      width: 670px;
      height: 300px;
      margin: 40px auto 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .explain_desc {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
        margin: 30px 0px 10px;
      }
      .explain_text {
        font: {
          size: 26px;
          weight: 400;
        }
        color: #777777;
        margin-top: 20px;
      }
    }
  }
}
.van-nav-bar {
  background: #2196f3;
  /deep/.van-nav-bar__left {
    .van-icon {
      color: #ffffff;
      font-size: 48px;
    }
  }
  /deep/ .van-nav-bar__title {
    font: {
      size: 30px;
      weight: 500;
    }
    color: #ffffff;
  }
}

.van-hairline--bottom::after {
  border: none;
}
</style>
